@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.about {
  align-self: center;
  font-size: 200%;
  font-weight: 500;
  color: #ffffff; /* Change text color */
  font-family: 'Roboto', sans-serif; /* Change font family to Roboto */
  text-align: center;
}

.about p {
  margin: 0;
  color: #ffffff; /* Change text color */
  font-family: 'Roboto', sans-serif; /* Change font family to Roboto */
  text-align: center;
}

/* Reset default margin and padding */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.header {
  background-color: transparent;
  height: 100px; /* Set the desired height for the header */
  width: 100%;
  display: flex;
  flex-direction: row; /* Adjust the desired layout direction */
  justify-content: center;
  position: fixed; /* Fix the header to the top */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure the header is above other content */
  text-align: center; 
}

.home {
  width: 100%;
  height: 100vh;
  background-image: url('../../../public/banner.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  left: 0;
  padding-top: 100px; /* Add padding to account for the fixed header height */
}